export const jukovskiy = [
    {date: "06.03.2023", link: "https://ftkl.ru/9268/364812"},
    {date: "07.03.2023", link: "https://ftkl.ru/9268/023654"},
    {date: "08.03.2023", link: "https://ftkl.ru/9268/175294"},
    {date: "09.03.2023", link: "https://ftkl.ru/9268/941530"},
    {date: "10.03.2023", link: "https://ftkl.ru/9268/281365"},
    {date: "11.03.2023", link: "https://ftkl.ru/9268/923684"},
    {date: "12.03.2023", link: "https://ftkl.ru/9268/084532"},
    {date: "13.03.2023", link: "https://ftkl.ru/9268/593820"},
    {date: "14.03.2023", link: "https://ftkl.ru/9268/427108"},
    {date: "15.03.2023", link: "https://ftkl.ru/9268/875961"},
    {date: "16.03.2023", link: "https://ftkl.ru/9268/653107"},
    {date: "17.03.2023", link: "https://ftkl.ru/9268/658712"},
    {date: "18.03.2023", link: "https://ftkl.ru/9268/267598"},
    // {date: "19.03.2023", link: "https://ftkl.ru/9268/190673"},
    // {date: "20.03.2023", link: "https://ftkl.ru/9268/893152"},
    // {date: "21.03.2023", link: "https://ftkl.ru/9268/241576"},
    // {date: "22.03.2023", link: "https://ftkl.ru/9268/126435"},
    // {date: "23.03.2023", link: "https://ftkl.ru/9268/496281"},
    // {date: "24.03.2023", link: "https://ftkl.ru/9268/105463"},
    // {date: "25.03.2023", link: "https://ftkl.ru/9268/281493"},
    // {date: "26.03.2023", link: "https://ftkl.ru/9268/587162"},
    // {date: "27.03.2023", link: "https://ftkl.ru/9268/425613"},
    // {date: "28.03.2023", link: "https://ftkl.ru/9268/463280"},
    // {date: "29.03.2023", link: "https://ftkl.ru/9268/463280"},
    // {date: "30.03.2023", link: "https://ftkl.ru/9268/463280"},
    // {date: "31.03.2023", link: "https://ftkl.ru/9268/463280"},
];

export const lytkaryno = [
    {date: "06.03.2023", link: "#"},
    {date: "07.03.2023", link: "#"},
    {date: "08.03.2023", link: "#"},
    {date: "09.03.2023", link: "#"},
    {date: "10.03.2023", link: "#"},
    {date: "11.03.2023", link: "#"},
    {date: "12.03.2023", link: "#"},
    {date: "13.03.2023", link: "#"},
    {date: "14.03.2023", link: "#"},
    {date: "15.03.2023", link: "#"},
    {date: "16.03.2023", link: "#"},
    {date: "17.03.2023", link: "#"},
    {date: "18.03.2023", link: "#"},
    // {date: "19.03.2023", link: "#"},
    // {date: "20.03.2023", link: "#"},
    // {date: "21.03.2023", link: "#"},
    // {date: "22.03.2023", link: "#"},
    // {date: "23.03.2023", link: "#"},
    // {date: "24.03.2023", link: "#"},
    // {date: "25.03.2023", link: "#"},
    // {date: "26.03.2023", link: "#"},
    // {date: "27.03.2023", link: "#"},
    // {date: "28.03.2023", link: "#"},
    // {date: "29.03.2023", link: "#"},
    // {date: "30.03.2023", link: "#"},
    // {date: "31.03.2023", link: "#"},
];

export const serpuhov = [
    {date: "06.03.2023", link: "https://ftkl.ru/9268/715236"},
    {date: "07.03.2023", link: "https://ftkl.ru/9268/642193"},
    {date: "08.03.2023", link: "https://ftkl.ru/9268/387069"},
    {date: "09.03.2023", link: "https://ftkl.ru/9268/675498"},
    {date: "10.03.2023", link: "https://ftkl.ru/9268/325879"},
    {date: "11.03.2023", link: "https://ftkl.ru/9268/829346"},
    {date: "12.03.2023", link: "https://ftkl.ru/9268/074289"},
    {date: "13.03.2023", link: "https://ftkl.ru/9268/537482"},
    {date: "14.03.2023", link: "https://ftkl.ru/9268/850163"},
    {date: "15.03.2023", link: "https://ftkl.ru/9268/978324"},
    {date: "16.03.2023", link: "https://ftkl.ru/9268/250347"},
    {date: "17.03.2023", link: "https://ftkl.ru/9268/917648"},
    {date: "18.03.2023", link: "https://ftkl.ru/9268/950132"},
    // {date: "19.03.2023", link: "https://ftkl.ru/9268/190673"},
    // {date: "20.03.2023", link: "https://ftkl.ru/9268/893152"},
    // {date: "21.03.2023", link: "https://ftkl.ru/9268/241576"},
    // {date: "22.03.2023", link: "https://ftkl.ru/9268/126435"},
    // {date: "23.03.2023", link: "https://ftkl.ru/9268/496281"},
    // {date: "24.03.2023", link: "https://ftkl.ru/9268/105463"},
    // {date: "25.03.2023", link: "https://ftkl.ru/9268/281493"},
    // {date: "26.03.2023", link: "https://ftkl.ru/9268/587162"},
    // {date: "27.03.2023", link: "https://ftkl.ru/9268/425613"},
    // {date: "28.03.2023", link: "https://ftkl.ru/9268/463280"},
    // {date: "29.03.2023", link: "https://ftkl.ru/9268/463280"},
    // {date: "30.03.2023", link: "https://ftkl.ru/9268/463280"},
    // {date: "31.03.2023", link: "https://ftkl.ru/9268/463280"},
];

export const barviha = [
    {date: "06.03.2023", link: "#"},
    {date: "07.03.2023", link: "#"},
    {date: "08.03.2023", link: "#"},
    {date: "09.03.2023", link: "#"},
    {date: "10.03.2023", link: "#"},
    {date: "11.03.2023", link: "#"},
    {date: "12.03.2023", link: "#"},
    {date: "13.03.2023", link: "#"},
    {date: "14.03.2023", link: "#"},
    {date: "15.03.2023", link: "#"},
    {date: "16.03.2023", link: "#"},
    {date: "17.03.2023", link: "#"},
    {date: "18.03.2023", link: "#"},
    // {date: "19.03.2023", link: "#"},
    // {date: "20.03.2023", link: "#"},
    // {date: "21.03.2023", link: "#"},
    // {date: "22.03.2023", link: "#"},
    // {date: "23.03.2023", link: "#"},
    // {date: "24.03.2023", link: "#"},
    // {date: "25.03.2023", link: "#"},
    // {date: "26.03.2023", link: "#"},
    // {date: "27.03.2023", link: "#"},
    // {date: "28.03.2023", link: "#"},
    // {date: "29.03.2023", link: "#"},
    // {date: "30.03.2023", link: "#"},
    // {date: "31.03.2023", link: "#"},
];

export const krasnogorsk = [
    {date: "06.03.2023", link: "#"},
    {date: "07.03.2023", link: "#"},
    {date: "08.03.2023", link: "#"},
    {date: "09.03.2023", link: "#"},
    {date: "10.03.2023", link: "#"},
    {date: "11.03.2023", link: "#"},
    {date: "12.03.2023", link: "#"},
    {date: "13.03.2023", link: "#"},
    {date: "14.03.2023", link: "#"},
    {date: "15.03.2023", link: "#"},
    {date: "16.03.2023", link: "#"},
    {date: "17.03.2023", link: "#"},
    {date: "18.03.2023", link: "#"},
    // {date: "19.03.2023", link: "#"},
    // {date: "20.03.2023", link: "#"},
    // {date: "21.03.2023", link: "#"},
    // {date: "22.03.2023", link: "#"},
    // {date: "23.03.2023", link: "#"},
    // {date: "24.03.2023", link: "#"},
    // {date: "25.03.2023", link: "#"},
    // {date: "26.03.2023", link: "#"},
    // {date: "27.03.2023", link: "#"},
    // {date: "28.03.2023", link: "#"},
    // {date: "29.03.2023", link: "#"},
    // {date: "30.03.2023", link: "#"},
    // {date: "31.03.2023", link: "#"},
];

export const megaDybenko = [
    {date: "06.03.2023", link: "#"},
    {date: "07.03.2023", link: "#"},
    {date: "08.03.2023", link: "#"},
    {date: "09.03.2023", link: "#"},
    {date: "10.03.2023", link: "#"},
    {date: "11.03.2023", link: "#"},
    {date: "12.03.2023", link: "#"},
    {date: "13.03.2023", link: "#"},
    {date: "14.03.2023", link: "#"},
    {date: "15.03.2023", link: "#"},
    {date: "16.03.2023", link: "#"},
    {date: "17.03.2023", link: "#"},
    {date: "18.03.2023", link: "#"},
    // {date: "19.03.2023", link: "#"},
    // {date: "20.03.2023", link: "#"},
    // {date: "21.03.2023", link: "#"},
    // {date: "22.03.2023", link: "#"},
    // {date: "23.03.2023", link: "#"},
    // {date: "24.03.2023", link: "#"},
    // {date: "25.03.2023", link: "#"},
    // {date: "26.03.2023", link: "#"},
    // {date: "27.03.2023", link: "#"},
    // {date: "28.03.2023", link: "#"},
    // {date: "29.03.2023", link: "#"},
    // {date: "30.03.2023", link: "#"},
    // {date: "31.03.2023", link: "#"},
];

export const madagaskar = [
    {date: "06.03.2023", link: "#"},
    {date: "07.03.2023", link: "#"},
    {date: "08.03.2023", link: "#"},
    {date: "09.03.2023", link: "#"},
    {date: "10.03.2023", link: "#"},
    {date: "11.03.2023", link: "#"},
    {date: "12.03.2023", link: "#"},
    {date: "13.03.2023", link: "#"},
    {date: "14.03.2023", link: "#"},
    {date: "15.03.2023", link: "#"},
    {date: "16.03.2023", link: "#"},
    {date: "17.03.2023", link: "#"},
    {date: "18.03.2023", link: "#"},
    // {date: "19.03.2023", link: "#"},
    // {date: "20.03.2023", link: "#"},
    // {date: "21.03.2023", link: "#"},
    // {date: "22.03.2023", link: "#"},
    // {date: "23.03.2023", link: "#"},
    // {date: "24.03.2023", link: "#"},
    // {date: "25.03.2023", link: "#"},
    // {date: "26.03.2023", link: "#"},
    // {date: "27.03.2023", link: "#"},
    // {date: "28.03.2023", link: "#"},
    // {date: "29.03.2023", link: "#"},
    // {date: "30.03.2023", link: "#"},
    // {date: "31.03.2023", link: "#"},
];

